<template>
  <CModal
    title="Kết quả dự thưởng"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <div v-if="giftResp" class="text-center">
          <p>
            Mã dự thưởng: <strong>{{ giftResp.giftCode }}</strong>
          </p>
          <h3 class="text-success">{{ giftResp.message }}</h3>
          <CIcon
            :name="giftResp.icon"
            custom-classes="c-icon text-danger"
            style="width: 3rem; height: 3rem;"
          />
          <p v-if="giftResp.giftName">
            <strong>{{ giftResp.giftName }}</strong>
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="giftResp && giftResp.giftName">
        Bạn nhớ chụp màn hình lại để nhận quà nhé!
      </div>
      <CButton v-else class="btn btn-light" @click="detailModal = false">
        <CIcon name="cil-x-circle" custom-classes="c-icon m-0" /> Đóng
      </CButton>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["giftResp"],
  data() {
    return {
      detailModal: false,
    };
  },
  watch: {
    "giftResp.message"(val) {
      this.detailModal = !!val;
    },
    detailModal(val) {
      if (!val) {
        this.$emit("update:giftResp", {
          message: null,
          icon: "cil-gift",
          giftName: null,
          giftCode: null,
        });
      }
    },
  },
};
</script>
