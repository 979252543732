<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol v-if="shopName" md="8">
          <CCardGroup>
            <CCard
              style="background-color:#1361a0;"
              text-color="white"
              class="text-center py-5"
              body-wrapper
            >
              <CCardBody>
                <img src="/img/logo.png" />
                <h3>Apollo <strong>LDS</strong></h3>
              </CCardBody>
            </CCard>
            <CCard>
              <CCardBody>
                <CForm>
                  <h2>NHẬP MÃ TRÚNG THƯỞNG</h2>
                  <p class="text-muted">
                    Nhập Tên, Số điện thoại và Mã dự thưởng của bạn để có cơ hội
                    trúng các giải thưởng hấp dẫn.
                  </p>
                  <h4>
                    Cửa hàng: <strong>{{ shopName }}</strong>
                  </h4>
                  <CInput
                    placeholder="Tên của bạn"
                    type="text"
                    v-model="fullName"
                    :add-input-classes="{
                      'is-invalid': !!inValidObject['fullName'],
                    }"
                    :invalid-feedback="inValidObject['fullName']"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>
                  <CInput
                    placeholder="Số điện thoại"
                    type="text"
                    v-model="mobile"
                    :add-input-classes="{
                      'is-invalid': !!inValidObject['mobile'],
                    }"
                    :invalid-feedback="inValidObject['mobile']"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-mobile" />
                    </template>
                  </CInput>
                  <CInput
                    placeholder="Mã dự thưởng"
                    type="text"
                    v-model="luckyCode"
                    :add-input-classes="{
                      'is-invalid': !!inValidObject['luckyCode'],
                    }"
                    :invalid-feedback="inValidObject['luckyCode']"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-barcode" />
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CSpinner
                        v-if="loading"
                        color="info"
                        size="sm"
                        class="ml-2"
                      />
                      <CButton
                        v-else
                        style="background-color:#1361a0;"
                        class="px-4 text-light"
                        @click="getLucky"
                      >
                        <CIcon name="cil-gift" />
                        Nhận thưởng
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
        <CCol v-else>
          <div class="text-center text-danger">
            <h3>
              <CIcon name="cil-ban" size="xl" />
              Cửa hàng này không tồn tại!
            </h3>
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <alertModal :giftResp.sync="giftResp" />
  </div>
</template>
<script>
import alertModal from "./Alert.vue";

export default {
  components: { alertModal },
  name: "Lucky",
  data() {
    return {
      shopName: null,
      fullName: null,
      luckyCode: null,
      mobile: null,
      loading: false,
      inValidObject: {},
      giftResp: {
        message: null,
        icon: "cil-gift",
        giftName: null,
        giftCode: null,
      },
    };
  },
  async mounted() {
    let shopName = null;
    let alias = this.shopAlias;
    if (alias) {
      let resp = await this.$http.get(`/lucky/check-shop/${alias}`);
      if (
        resp &&
        resp.status == 200 &&
        resp.data &&
        resp.data.status == this.$const.RESPONSE_TYPES.Success
      ) {
        shopName = resp.data.data;
      }
    }
    this.shopName = shopName;
  },
  computed: {
    shopAlias() {
      return this.$route.params.shopAlias;
    },
  },
  methods: {
    async getLucky() {
      let fullName = this.fullName;
      let mobile = this.mobile;
      let luckyCode = this.luckyCode;
      let shopAlias = this.shopAlias;
      this.inValidObject = this.checkValid(fullName, mobile, luckyCode);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.loading = true;
      try {
        var postedData = {
          Name: fullName,
          Mobile: mobile,
          LuckyCode: luckyCode.toUpperCase(),
          ShopAlias: shopAlias,
        };
        let resp = await this.$http.post(`/lucky/join-reward`, postedData);
        if (resp && resp.status == 200) {
          if (
            resp.data &&
            resp.data.status == this.$const.RESPONSE_TYPES.Success
          ) {
            if (resp.data.data) {
              this.giftResp = {
                message: `Chúc mừng bạn ${fullName}!`,
                icon: "cil-Gift",
                giftName: resp.data.data,
                giftCode: luckyCode.toUpperCase(),
              };
            } else {
              this.giftResp = {
                message: resp.data.message,
                icon: "cil-Gift",
                giftName: null,
                giftCode: luckyCode.toUpperCase(),
              };
            }
            this.luckyCode = null;
          } else {
            alert(resp.data.message);
          }
        }
      } catch (error) {
        alert(error);
      }
      this.loading = false;
    },
    checkValid(fullName, mobile, luckyCode) {
      let inValidObject = {};

      if (!fullName) inValidObject["fullName"] = "Vui lòng nhập Tên của bạn!";
      if (!mobile || mobile.length != 10 || isNaN(mobile)) {
        inValidObject["mobile"] = "Vui lòng nhập đúng Số điện thoại!";
      }

      if (!luckyCode)
        inValidObject["luckyCode"] = "Vui lòng nhập Mã dự thưởng!";

      return inValidObject;
    },
  },
};
</script>
